.Projects {
  height: 300px;
  width: 325px;
}

.Hira {
  font-family: "Goldman", cursive;
  margin-top: -50px;
}

.ravenous {
  font-weight: 600;
  margin-top: -50px;
}

.Btn {
  background-color: transparent;
  border: none;
  color: #001334;
  text-decoration: none;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 8px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px #b7bfc7;
}

/* .GitHubBtn {
  background-color: transparent;
  color: black;
  border: 2px solid black;
} */

.Btn:hover {
  background-color: #1b3561;
  color: #15d07f;
  box-shadow: 0px 1px 10px #b7bfc7;
}
