.Intro {
    display: grid;
 
}


.Greeting {
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    font-size: 1.7em;
    margin: 150px 60px;
}

.Name {
    color: #2c393f;
    font-weight: 400;
}